import React from "react";
import "../assets/css/MiCurso.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import Contexto from "../context/Contexto";
import InfoPrograma from "./InfoPrograma";
import { useEffect, useState } from "react";

const MiCursoPendiente = (props) => {
  const { Griseado, productos, dameMiCurso, activaCyberZombie } =
    useContext(Contexto);
  const { curso, meses, info } = props;
  const [datita, setDatita] = useState({});

  useEffect(() => {
    productos.forEach((element) => {
      if (element.url == "/" + curso) {
        setDatita(element);
      }
    });
  }, [productos]);
  let handleHabilitar = () => {
    dameMiCurso({ cursin: [curso], meses: meses });
  };
  return (
    <>
      <div
        className={"item-curso-pendiente"}
        style={{ background: datita?.colorin ? datita?.colorin : "" }}
      >
        <p style={{ padding: "5px", paddingTop: "15px" }}>{datita.nombre}</p>
        {datita && (
          <img
            src={`https://escueladevrock.com/${datita?.logo}`}
            alt={datita.nombre}
            className="micurso-loguito-pendiente"
          />
        )}
        <p>
          {" "}
          <br></br>
          {meses} {meses > 1 ? "meses disponibles" : "mes disponible"}
        </p>
        <button className="btn" onClick={handleHabilitar}>
          Habilitar
        </button>
      </div>
    </>
  );
};

export default MiCursoPendiente;
