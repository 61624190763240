import React, { useContext, useEffect, useState } from "react";
import Contexto from "../context/Contexto";
import parse from "react-html-parser";
import "../assets/css/Summerhack.css";
import { useNavigate } from "react-router-dom";
import Colonia from "./Colonia";

const SummerDescuentos25 = () => {
  const {
    usuario,
    influencers,
    loguearUsuario,
    desconectarUsuario,
    cursoscomprados,
    cambiaPrecio,
    agregarCarrito,
    grisea,
  } = useContext(Contexto);
  const [precioFinal, setPrecioFinal] = useState(150000);
  const [mensaje, setMensaje] = useState("¡Early Bird 30%OFF!");
  const [descuentardo, setDescuentardo] = useState(45000);
  const [descuen, setDescuen] = useState(45000);
  const [textito, setTextito] = useState("");
  const [colonia, setColonia] = useState(0);
  const [descuentoEarly, setDescuentoEarly] = useState(45000);
  const [meses, setMeses] = useState(3);
  const [precioFull, setPrecioFull] = useState(150000);
  const [descuenFinal, setDescuenFinal] = useState(45000);
  const navigate = useNavigate();

  useEffect(() => {
    calcularDescuento();
    console.log(cursoscomprados);
  }, [cursoscomprados]);
  const calcularDescuento = () => {
    console.log("llama a calcular descuento");
    if (
      cursoscomprados.summerhack23 ||
      cursoscomprados.summerhack2022 ||
      cursoscomprados.summerhack24 ||
      cursoscomprados.summerhack2021 ||
      cursoscomprados.summerhack2021feb ||
      cursoscomprados.winterhack ||
      cursoscomprados.winterhack23 ||
      cursoscomprados.easterhack
    ) {
      console.log("TIENE ANTERIOR");
      setColonia(1);
      //   setPrecioFinal(80000);
      //   setDescuentardo(70000);
      //   console.log("LE descontamos perri");
      //   setMensaje(
      //     " Estás comprando con el descuento deEsrlyBird 30%OFF y, ¡wow! <br> Te restamos un 20% más por ser exalumnx del programa. "
      //   );
      setPrecioFinal(160000);
    } else {
      setPrecioFinal(160000);
      //   setDescuentardo(45000);
    }
  };

  let comprarlo = () => {
    agregarCarrito("/summerhack", "Dale perro");
    navigate("/carrito");
  };

  if (colonia) {
    return <Colonia></Colonia>;
  } else {
    return (
      <>
        <div className="comboOferta centerX comprarStick">
          <>
            {/* <h4 className="precioViejo">
            {precioFull < precioFinal}
            {<del>AR$ {precioFull}</del>}
          </h4> */}

            <del>
              <h1 className="precioFinal">AR$ 210.000</h1>
            </del>
            <h4>
              Somos 50K en instagram y celebramos con <br />
              <strong>50K OFF por este finde</strong>
            </h4>
            <h1 className="precioFinal">AR$ {precioFinal}</h1>

            {/* <p>
            {parse(mensaje)}{" "}
            <strong>
              <h6>Te ahorrás AR${descuentardo} </h6>
            </strong>
          </p> */}
          </>
          <p>
            Precio completo por los 12 meses <br></br>
            <strong>Pagalo hasta en 6 cuotas sin interés</strong> <br />
            Si estás en el exterior dentro de esta opción podés pagar con PayPal
          </p>

          {usuario !== null ? (
            <>
              <button
                className="btn btn-primary"
                onClick={() => {
                  comprarlo();
                  grisea;
                }}
              >
                COMPRAR TARJETA / TRANSFERENCIA / PAYPAL
              </button>
              <br />
              <br /> <br />
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  desconectarUsuario();
                }}
              >
                Desconectame
              </button>
            </>
          ) : (
            <>
              <p>Logueate con tu usuario para poder sumarte al programa.</p>
              <button
                className="btn btn-primary"
                onClick={() => {
                  loguearUsuario();
                }}
              >
                LOGUEAME Y ANOTAME!
              </button>
            </>
          )}

          <div class="transfer-info">
            <h2>.</h2>
            <p>
              El precio por transferencia es de <strong>160.000 pesos</strong>.
            </p>
            <p>
              Por favor, realizá la transferencia al alias{" "}
              <strong>codearock</strong>.
            </p>
            <p>
              Una vez hecha, enviá el comprobante junto con:
              <ul>
                <li>
                  El correo electrónico que usás para ingresar a la plataforma.
                </li>
                <li>
                  Tu cuenta de Instagram al siguiente perfil:
                  <a href="https://instagram.com/codearock" target="_blank">
                    @codearock
                  </a>
                  .
                </li>
              </ul>
            </p>
            <p>
              De esta forma podremos cargar el programa en tu cuenta. ¡Gracias
              por confiar en nosotros! 🚀
            </p>
          </div>
        </div>
      </>
    );
  }
};

export default SummerDescuentos25;
