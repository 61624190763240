import "../assets/css/Colonia.css";
function Colonia() {
  return (
    <>
      <br />
      <br />
      <br />
      <main class="main">
        <p>
          ¡Bienvenidos como sos ex podes comprar acceso a la Colonia de Verano!
          Este programa exclusivo está diseñado para ex-alumnos de{" "}
          <strong>SummerHack</strong>, <strong>WinterHack</strong> y{" "}
          <strong>AstroHack</strong>.
        </p>
        <p>Con tu inscripción, obtendrás acceso a:</p>
        <ul class="list">
          <li class="list-item">
            Todos los videos del <strong>SummerHack</strong> desde el primer
            día.
          </li>
          <li class="list-item">
            Jams y correcciones del <strong>SummerHack 2025</strong> (que
            iniciarán al completar el cupo de 40 participantes).
          </li>
          <li class="list-item">Acceso a todo este material por un año.</li>
        </ul>
        <div class="prices">
          <h2>Precios:</h2>
          <ul class="list">
            <li class="list-item">
              <strong>90 mil pesos</strong> con tarjeta (en 6 cuotas sin
              interés). Solicitale a Jony por instagram el Link para pagar con
              cuotas.
            </li>
            <li class="list-item">
              <strong>65 mil pesos</strong> por transferencia. Transferí al
              aleas: <strong>codearock</strong> <br />a nombre de: Jonatan
              Ariste Ojeda. Manda el comprobante por instagram del pago y pasame
              tu email para cargarte la colonia.
            </li>
          </ul>
        </div>
        <p>¡No te pierdas la oportunidad de seguir creciendo con nosotros!</p>
      </main>
      <footer class="footer">
        &copy; 2025 CodeaRock. Todos los derechos reservados.
      </footer>
    </>
  );
}

export default Colonia;
